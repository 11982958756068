<template>
  <v-card-item class="py-4 px-6">
    <div class="v-card-item__content">
      <div class="d-sm-flex align-center justify-space-between">
        <v-card-title
          prepend-icon="mdi-update"
          class="text-md-h6 text-body-1 text-wrap font-weight-bold"
          >Соглашение на обработку персональных данных</v-card-title
        >
      </div>
    </div>
  </v-card-item>
  <v-divider></v-divider>
  <v-card-text class="elevation-0 rounded-md v-card--variant-elevated">
    <p style="text-align: justify">
      Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от
      27.07.2006 г. я, пользователь Интернет-сайта http://miac-tmn.ru/ (далее также –
      «Пользователь», «Субъект персональных данных»), даю свое согласие ГАУ ТО «МИАЦ» на
      обработку своих персональных данных при размещении (вводе) своих персональных данных
      на Интернет-сайте: http://miac-tmn.ru/ путем заполнения форм, анкет, регистрации и
      совершения иных действий, связанных с необходимостью предоставления моей
      персональной (личной) информации в целях эффективного направления запросов и
      получения ответов от ГАУ ТО «МИАЦ», а также использования Интернет-сайта:
      http://miac-tmn.ru/
    </p>
    <br />
    <p style="text-align: justify">
      Настоящее согласие распространяется на следующие мои персональные данные: фамилия,
      имя и отчество, адрес электронной почты, почтовый адрес доставки почтовой
      корреспонденции (ответ на запрос), контактный телефон.
    </p>
    <br />
    <p style="text-align: justify">
      Настоящее согласие предоставляется на осуществление любых действий в отношении
      персональных данных, которые необходимы и желаемы для достижения вышеуказанных
      целей, включая без ограничения сбор, систематизацию, накопление, хранение, уточнение
      (обновление, изменение), использование, распространение (в том числе передача),
      обезличивание, блокирование, уничтожение, трансграничную передачу персональных
      данных, а также осуществление любых иных действий с персональными данными с учетом
      действующего законодательства РФ об обеспечении конфиденциальности персональных
      данных и безопасности персональных данных при их обработке.
    </p>
    <br />
    <p style="text-align: justify">
      Обработка персональных данных осуществляется ГАУ ТО «МИАЦ» с использованием средств
      автоматизации, в том числе с использованием информационных технологий и технических
      средств, включая средства вычислительной техники, информационно-вычислительные
      комплексы и сети, средства и системы передачи, приема и обработки персональных
      данных (средства и системы звукозаписи, звукоусиления, звуковоспроизведения,
      переговорные и телевизионные устройства, средства изготовления, тиражирования
      документов и другие технические средства обработки речевой, графической, видео- и
      буквенно-цифровой информации), программные средства (операционные системы, системы
      управления базами данных и т. п.), средства защиты информации, применяемые в
      информационных системах, а также без использования средств автоматизации.
    </p>
    <br />
    <p style="text-align: justify">
      Настоящее согласие на обработку персональных данных дается мной бессрочно и может
      быть отозвано Пользователем посредством направления письменного заявления в адрес
      ГАУ ТО «МИАЦ» по адресу: 6625023, РФ, Тюменская область, г. Тюмень, ул. Республики,
      д. 169a, корпус 1, 6-й этаж или по адресу электронной почты: info@miac-tmn.ru и
      miac@med-to.ru. Настоящим я признаю и подтверждаю, что с правами и обязанностями в
      соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006
      г., в том числе порядком отзыва согласия на обработку персональных данных, а также
      Политикой ГАУ ТО «МИАЦ» в отношении обработки персональных данных» ознакомлен.
    </p>
    <br />
    <p style="text-align: justify">
      Гарантирую, что представленная мной информация является полной, точной и
      достоверной, а также что при представлении информации не нарушаются действующее
      законодательство Российской Федерации, законные права и интересы третьих лиц. Вся
      представленная информация заполнена мною в отношении себя лично.
    </p>
    <br />
    <p style="text-align: justify">
      Настоящее согласие действует в течение всего периода хранения персональных данных,
      если иное не предусмотрено законодательством Российской Федерации.
    </p>
    <br />
    <p style="text-align: justify">
      Ознакомится с Политикой оператора в отношении обработки персональных данных в
      государственном автономном учреждении Тюменской области «Медицинский
      информационно-аналитический центр»
    </p>
  </v-card-text>
</template>

<script>
export default {
  name: "PoliticsDetails",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
};
</script>
