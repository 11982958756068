<template>
  <h4 class="mb-4 text-h6 font-weight-bold">Введите адрес электронной почты</h4>
  <v-form @submit.prevent="handleForgot">
    <v-text-field
      v-model="email"
      :rules="[rulesEmail.required, rulesEmail.emailMatch]"
      label="Email"
      variant="solo"
    ></v-text-field>
    <v-alert
      v-if="message"
      icon="mdi-car-brake-alert"
      :class="alert"
      class="mb-5"
      role="alert"
    >
      {{ message }}
    </v-alert>
    <v-btn
      v-if="!status"
      class="button-height mt-2 v-btn--flat text-white bg-green v-btn--density-default v-btn--size-large v-btn--variant-elevated text-none"
      type="submit"
      :loading="loading"
      block
      >Сбросить пароль</v-btn
    >
  </v-form>

  <div class="new-account mt-8">
    <router-link :to="{ name: 'Login' }" class="back_to"
      ><v-icon icon="mdi-keyboard-backspace"></v-icon> Страница авторизации
    </router-link>
  </div>
</template>

<script>
import AuthService from "../services/auth.service";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      message: "",
      status: false,
      alert: "bg-deep-orange-darken-4",
      dialog: false,
      checked: false,
      rulesEmail: {
        required: (value) => !!value || "Обязательное поле.",
        emailMatch: (v) =>
          v.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || "Невалидный Email адрес",
      },
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    async handleForgot() {
      this.loading = true;
      this.message = "";
      this.status = false;

      await AuthService.forgotPass(this.email)
        .then((res) => {
          if (res.status == 200) {
            setTimeout(() => {
              this.alert = "bg-green";
              this.message = "Проверьте свою электронную почту";
              this.loading = false;
              this.status = true;
            }, 1000);
          }
        })
        .catch((err) => {
          this.message = err.response.data.detail;
          this.alert = "bg-deep-orange-darken-4";
          this.loading = false;
        });
    },
  },
  mounted() {},
};
</script>
