<template>
  <component :is="layout"><router-view /></component>
</template>

<script>
import AppLayoutDefault from "./AppLayoutDefault.vue";
import AppLayoutAdmin from "./AppLayoutAdmin.vue";
import AppLayoutLogin from "./AppLayoutLogin.vue";
import AppLayoutEmpty from "./AppLayoutEmpty.vue";

export default {
  data() {
    return {};
  },
  components: {
    AppLayoutDefault,
    AppLayoutAdmin,
    AppLayoutLogin,
    AppLayoutEmpty,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || AppLayoutDefault;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    checkStatus() {
      if (
        this.$route.path == "/forgot-password" ||
        this.$route.path == "/reset-password"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    // console.log(this.loggedIn);
    // console.log(this.checkStatus);
    // if (!this.loggedIn && this.checkStatus) {
    //   console.log("Reset pass");
    // } else if (this.loggedIn && !this.checkStatus) {
    //   this.$router.push("login");
    // } else if (!this.loggedIn && !this.checkStatus) {
    //   this.$router.push("login");
    // }
  },
};
</script>
