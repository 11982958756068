<template>
  <AppMenuDefault />
  <slot />
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
};
</script>
