<template>
  <v-app>
    <AppLayout />
  </v-app>
</template>

<script>
import AppLayout from "@/layouts/AppLayout.vue";

export default {
  name: "App",
  components: {
    AppLayout,
  },
};
</script>
