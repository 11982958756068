
import { useCookies } from "vue3-cookies";


export default function authHeader() {
  const { cookies } = useCookies();
  let token = cookies.get("access_token")

  if (token) {
    return token;
  } else {
    return {};
  }
}
