import axios from 'axios';
import authHeader from './auth-header';

// const API_URL = 'http://192.168.188.27:8000/v1/';
const API_URL = 'https://ht-server.dz72.ru/v1/';

class UserService {
  getMeUser() {
    return axios.get(API_URL + 'users/me', {
      withCredentials: true,
      headers: { access_token: authHeader() }
    })
  }

  updateUser(user) {
    console.log(user)
    return axios.post(API_URL + 'users/update', {
      'username': user.username,
      'email': user.email,
      'password': user.password,
      'firstname': user.firstname
    }, {
      withCredentials: true,
      headers: { access_token: authHeader() }
    })
  }

  getUsersList(page) {
    return axios.get(`${API_URL}auth/all-users?page=${page}&size=10`, {
      withCredentials: true,
      headers: { access_token: authHeader() }
    })
  }

  getUserDelete(user_id) {
    return axios.post(`${API_URL}auth/delete`, {
      'user_id': user_id
    }, {
      withCredentials: true,
      headers: { access_token: authHeader() }
    })
  }

  getUserAdd(addNewUser) {
    console.log(addNewUser)
    return axios.post(`${API_URL}auth/register`, {
      "username": addNewUser.username,
      "email": addNewUser.email,
      "password": addNewUser.password,
      "firstname": addNewUser.firstname,
      "lastname": addNewUser.firstname
    }, {
      withCredentials: true,
      headers: { access_token: authHeader() }
    })
  }

  getUserAdminUpdate(userAdminUpd) {
    return axios.post(API_URL + 'auth/update-admin', {
      "user_id": userAdminUpd.id,
      'username': userAdminUpd.username,
      'email': userAdminUpd.email,
      'password': userAdminUpd.password,
      'firstname': userAdminUpd.firstname,
      'update_roles': userAdminUpd.roles
    }, {
      withCredentials: true,
      headers: { access_token: authHeader() }
    })
  }

  getCategories() {
    return axios.get(`${API_URL}categories`, {
      withCredentials: true,
      headers: { access_token: authHeader() }
    })
  }

  postCategories(newCategory) {
    return axios.post(`${API_URL}categories/create`, {
      "name": newCategory
    }, {
      withCredentials: true,
      headers: { access_token: authHeader() }
    })
  }

  postCategoriesUpdate(updCategory) {
    return axios.post(`${API_URL}categories/update`, updCategory, {
      withCredentials: true,
      headers: { 'Content-Type': 'application/json', access_token: authHeader() }
    })
  }

  postCategoriesDelete(idx) {
    return axios.post(`${API_URL}categories/delete`, {
      "category_id": idx
      }, {
      withCredentials: true,
      headers: { access_token: authHeader() }
    })
  }

  postSubCategories(newSubCategory) {
    return axios.post(`${API_URL}categories/${newSubCategory.id}/subcategories`, {
      "name": newSubCategory.name
    }, {
      withCredentials: true,
      headers: { 'Content-Type': 'application/json', access_token: authHeader() }
    })
  }

  postSubCategoriesUpdate(updSubCategory) {
    return axios.post(`${API_URL}categories/update-subcategory`, updSubCategory, {
      withCredentials: true,
      headers: { 'Content-Type': 'application/json', access_token: authHeader() }
    })
  }

  getAllQuestions() {
    return axios.get(`${API_URL}question/all-questions`, {
      withCredentials: true,
      headers: { access_token: authHeader() }
    })
  }

  createQuestions(createQuestions) {
    return axios.post(`${API_URL}question/create`, createQuestions, {
      withCredentials: true,
      headers: { 'Content-Type': 'application/json', access_token: authHeader() }
    })
  }

  updateQuestions(updateQuestions) {
    return axios.post(`${API_URL}question/update`, updateQuestions, {
      withCredentials: true,
      headers: { 'Content-Type': 'application/json', access_token: authHeader() }
    })
  }

  postQuestionsDelete(quest) {
    return axios.post(`${API_URL}question/delete`, quest, {
      withCredentials: true,
      headers: { 'Content-Type': 'application/json', access_token: authHeader() }
    })
  }
  
  getAllQuestionsPagination(filter) {
    return axios.get(`${API_URL}question/pagination-questions?${filter.category_id}${filter.subcategory_id}page=${filter.page}&size=${filter.size}&max_size=100`, {
      withCredentials: true,
      headers: { access_token: authHeader() }
    })
  }

  postFullAmswer(item) {
    return axios.post(`${API_URL}question/question_by_id`, item, {
      withCredentials: true,
      headers: { 'Content-Type': 'application/json', access_token: authHeader() }
    })
  }

  searchQuestions(search) {
    return axios.get(`${API_URL}question/search?query=${search}`, {
      withCredentials: true,
      headers: { access_token: authHeader() }
    })
  }

  searchQuestionsFuzzy(search) {
    return axios.get(`${API_URL}question/search-fuzzy_search?query=${search}`, {
      withCredentials: true,
      headers: { access_token: authHeader() }
    })
  }

  uploadImage(image) {
    return axios.post(`${API_URL}question/upload-binary`, image, {
      withCredentials: true,
      headers: { access_token: authHeader() }
    })
  }

  analytics(data) {
    return axios.post(`${API_URL}analytics/write`, data, {
      withCredentials: true,
      headers: { 'Content-Type': 'application/json', access_token: authHeader() }
    })
  }

}


export default new UserService();