<template>
  <AppMenuDefault />
  <AppDrawer />
  <AppMain><slot /></AppMain>
</template>

<script>
import AppMenuDefault from "../components/AppMenuDefault.vue";
import AppDrawer from "../components/AppDrawer.vue";
import AppMain from "../components/AppMain.vue";

export default {
  data() {
    return {};
  },
  components: {
    AppMenuDefault,
    AppMain,
    AppDrawer,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {},
  mounted() {
    // if (!this.loggedIn) {
    //   this.$router.push("/login");
    // }
  },
};
</script>
