<template>
  <AppMenuDefault />
  <h2>Admin page</h2>
  <slot />
</template>

<script>
import AppMenuDefault from "../components/AppMenuDefault.vue";

export default {
  data() {
    return {};
  },
  components: {
    AppMenuDefault,
  },
};
</script>
