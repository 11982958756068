<template>
  <div class="tree-item d-flex justify-space-between flex-column answer">
    <div class="tree-item-top d-flex justify-space-between flex-column text-caption">
      <v-card-title
        class="d-block text-wrap text-subtitle-1 font-weight-bold"
        v-html="answer.text"
      ></v-card-title>
      <v-card-text v-html="answer.answer"></v-card-text>
    </div>
    <v-btn
      v-if="Object.keys(answer).length && answer.sub_questions.length > 0"
      class="text-none"
      size="small"
      @click="isOpen = !isOpen"
      flat
      ><span style="font-size: 24px; transform: rotate(-90deg)">&lsaquo;</span></v-btn
    >
    <div
      class="tree-item-2"
      v-if="answer.sub_questions && answer.sub_questions.length > 0"
    >
      <div v-if="isOpen">
        <answer v-for="child in answer.sub_questions" :answer="child" :key="child">
        </answer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "answer",
  props: {
    answer: Object,
  },
  emits: [],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {},
};
</script>
