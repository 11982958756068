export const user = {
  namespaced: true,
  state: {
    drawerStatus: false,
    viewCategory: 0,
  },
  actions: {
  },
  mutations: {

  }
};