import axios from 'axios';
import authHeader from './auth-header';
import router from '@/router';

// const API_URL = 'http://192.168.188.27:8000/v1/auth/';
const API_URL = 'https://ht-server.dz72.ru/v1/auth/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', user, {
        withCredentials: true
      })
      .then(async response => {
        return response.data;
      }).catch(err => {
        console.log(err)
      })
  }

  logout() {
    router.push("/login")
    return axios
    .get(API_URL + 'logout', {
      withCredentials: true
    })
    .then(async () => {
      router.push("/login")
    }).catch(err => {
      console.log(err)
    })
  }

  forgotPass(email) {
    return axios
      .post(API_URL + 'forgot-password', {"email": `${email}`})
  }

  resetPass(tokenPass) {
    return axios
      .post(API_URL + 'reset-password', {
        "token": `${tokenPass.token}`,
        "new_password": `${tokenPass.new_password}`
      }, {
        withCredentials: true
      })
  }

  refreshToken(token) {
    return axios
      .post(API_URL + 'token/refresh', {"refresh_token": `${token}`})
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      nickname: user.nickname,
      email: user.email,
      username: user.username,
      roles: user.roles,
      password: user.password
    }, { headers: authHeader() }
    );
  }
}

export default new AuthService();