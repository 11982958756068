<template>
  <v-main class="bg-grey-lighten-4">
    <v-container fluid tag="section" class="pa-4" aria-labelledby="title">
      <v-row>
        <v-col cols="12">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {},
  methods: {},
};
</script>
