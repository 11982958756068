<template>
  <v-navigation-drawer app left v-model="drawerStatus">
    <v-list lines="two" density="compact" nav v-model:opened="open">
      <v-list-item
        color="blue-grey-darken-4"
        to="/"
        @click="drawerClick"
        prepend-icon="mdi-home-outline"
        title="Главная"
      />
      <v-divider></v-divider>
      <v-list-group v-if="checkRoles('user')" value="Users">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-badge-account-alert-outline"
            title="Мои настройки"
          ></v-list-item>
        </template>
        <v-list-item
          color="blue-grey-darken-4"
          to="/profile"
          @click="drawerClick"
          title="Мой профиль"
        />
      </v-list-group>
      <v-divider></v-divider>
      <v-list-group
        v-if="checkRoles('moderator')"
        value="Moderators"
        color="brown-darken-4"
      >
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-cog-outline"
            title="Модерирование"
          ></v-list-item>
        </template>
        <v-list-item
          color="blue-grey-darken-4"
          to="/categories"
          @click="drawerClick"
          title="Категории"
        />
        <v-list-item
          color="blue-grey-darken-4"
          to="/questions"
          @click="drawerClick"
          title="Вопросы"
        />
      </v-list-group>
      <v-divider></v-divider>
      <v-list-group v-if="checkRoles('admin')" value="Admins">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-account-circle-outline"
            title="Адинистрирование"
          ></v-list-item>
        </template>
        <v-list-item to="/users" @click="drawerClick" title="Пользователи" />
      </v-list-group>
    </v-list>

    <template v-slot:append>
      <v-list bg-color="blue-grey-darken-2">
        <v-list-item :title="user.firstname" :subtitle="user.email">
          <template v-slot:prepend>
            <v-avatar rounded color="#fff">{{ firstElem(user.firstname) }}</v-avatar>
          </template></v-list-item
        >
      </v-list>
    </template>
    <v-divider></v-divider>
  </v-navigation-drawer>
</template>

<script>
import { useCookies } from "vue3-cookies";
import { jwtDecode } from "jwt-decode";
import userService from "@/services/user.service";

export default {
  data() {
    return {
      open: ["Мои настройки"],
      user: "",
    };
  },
  components: {},
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    drawerStatus() {
      return this.$store.state.user.drawerStatus;
    },
    decodeJWT() {
      const { cookies } = useCookies();
      const getCookies = cookies.get("access_token");

      if (!getCookies) {
        return false;
      }
      const decoded = jwtDecode(getCookies);
      const roles = decoded.roles;

      return roles;
    },
  },
  methods: {
    drawerClick() {
      this.$store.state.user.drawerStatus = !this.$store.state.user.drawerStatus;
    },
    checkRoles(role) {
      if (this.decodeJWT) {
        const checkRole = this.decodeJWT.includes(role);
        if (checkRole) {
          return true;
        } else {
          return false;
        }
      }
    },
    firstElem(name) {
      return Object(name)[0];
    },
    getUser() {
      userService
        .getMeUser()
        .then(async (msg) => {
          this.user = msg.data;
        })
        .catch(() => {
          this.$store.dispatch("auth/logout");
          this.$store.state.auth.status.loggedIn = false;
          this.$router.push("/login");
        });
    },
  },
  mounted() {
    this.decodeJWT;
    this.drawerStatus;
    if (this.loggedIn) {
      this.getUser();
    }
  },
};
</script>
