<template>
  <h4 class="mb-4 text-subtitle-1 font-weight-bold">Введите новый пароль</h4>
  <v-form @submit.prevent="handleLogin">
    <v-text-field
      v-model="password"
      :rules="[rulesPass.required, rulesPass.min]"
      label="Новый пароль"
      variant="solo"
    ></v-text-field>
    <v-text-field
      v-model="passwordSec"
      :rules="[rulesPassSec.required, rulesPassSec.min, rulesPassSec.double]"
      label="Новый пароль"
      variant="solo"
    ></v-text-field>
    <v-alert
      v-if="message"
      icon="mdi-car-brake-alert"
      :class="alert"
      class="mt-5 mb-5 alter-reset"
      v-html="message"
    >
    </v-alert>
    <v-btn
      v-if="!tokenErr"
      class="button-height mt-2 v-btn--flat text-white bg-green v-btn--density-default v-btn--size-large v-btn--variant-elevated text-none"
      type="submit"
      block
      >Сохранить</v-btn
    >
    <v-btn
      v-if="tokenErr"
      class="button-height mt-2 v-btn--flat text-white bg-green v-btn--density-default v-btn--size-large v-btn--variant-elevated text-none"
      type="submit"
      block
      to="/forgot-password"
      >Запросить новый токен
    </v-btn>
  </v-form>
  <div class="new-account mt-8">
    <router-link :to="{ name: 'Login' }" class="back_to"
      ><v-icon icon="mdi-keyboard-backspace"></v-icon> Страница авторизации
    </router-link>
  </div>
</template>

<script>
import AuthService from "../services/auth.service";

export default {
  name: "ForgotPassword",
  data() {
    return {
      password: "",
      passwordSec: "",
      token: null,
      message: "",
      tokenErr: false,
      alert: "bg-deep-orange-darken-4",
      dialog: false,
      checked: false,
      rulesPass: {
        required: (value) => !!value || "Обязательное поле.",
        min: (v) => v.length >= 6 || "Минимальная длина 6 символов",
      },
      rulesPassSec: {
        required: (value) => !!value || "Обязательное поле.",
        min: (v) => v.length >= 6 || "Минимальная длина 6 символов",
        double: (value) => value === this.password || "Пароли не совпадают",
      },
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    async handleLogin() {
      this.loading = true;
      this.message = "";
      this.tokenErr = false;

      const tokenPass = {
        token: this.token,
        new_password: this.password,
      };
      await AuthService.resetPass(tokenPass)
        .then((res) => {
          if (res.status == 200) this.$router.push("/login?resetpassword=1");
        })
        .catch((err) => {
          this.alert = "bg-deep-orange-darken-4";
          this.loading = false;
          if (err.response.status == 400) {
            this.tokenErr = true;
          }
          this.message = err.response.data.detail;
        });
    },
  },
  mounted() {
    let token = Object.keys(this.$route.query).length;
    if (token == 0) {
      this.message = "Не верно указан токен";
    } else if (this.$route.query.token) {
      this.token = this.$route.query.token;
    } else {
      this.$router.push("/");
    }
  },
};
</script>
