import { createWebHistory, createRouter } from "vue-router";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import ForgotPassword from "./views/ForgotPassword.vue";
import ResetPassword from "./views/ResetPassword.vue";
// Decode JWT
import { jwtDecode } from "jwt-decode";
import { useCookies } from "vue3-cookies";
import store from "./store"
import moment from 'moment'
import AuthService from "@/services/auth.service";



function decodeJWT() {
  const { cookies } = useCookies();

  const getCookies = cookies.get("access_token")

  if (getCookies == null) {
    return false
  } else {
    const decoded = jwtDecode(getCookies);
    return decoded
  }

}

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { layout: 'AppLayoutDefault' }
  },
  {
    path: "/home",
    component: Home,
    meta: { layout: 'AppLayoutDefault' }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { layout: 'AppLayoutLogin' }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { layout: 'AppLayoutLogin' }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { layout: 'AppLayoutLogin' }
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("./views/Profile.vue"),
    meta: { layout: 'AppLayoutDefault' },
    beforeEnter: (to, from, next) => {
      const roles = decodeJWT()
      if (!roles.roles) {
        return next("/login")
      }
      const found = roles.roles.some((element) => element == "user");
      const currentUser = store.state.auth.status.loggedIn;
      if (currentUser && found) {
        return next()
      } else {
        return next("/")
      }
    }
  },
  {
    path: "/users",
    name: "UsersList",
    component: () => import("./views/UsersList.vue"),
    meta: { layout: 'AppLayoutDefault' },
    beforeEnter: (to, from, next) => {
      const roles = decodeJWT()
      if (!roles.roles) {
        return next("/login")
      }
      const found = roles.roles.some((element) => element == "admin");
      const currentUser = store.state.auth.status.loggedIn;
      if (currentUser && found) {
        return next()
      } else {
        return next("/")
      }
    }
  },
  {
    path: "/categories",
    name: "Categories",
    component: () => import("./views/Categories.vue"),
    meta: { layout: 'AppLayoutDefault' },
    beforeEnter: (to, from, next) => {
      const roles = decodeJWT()

      if (!roles.roles) {
        return next("/login")
      }
      const found = roles.roles.some((element) => element == "moderator");
      const currentUser = store.state.auth.status.loggedIn;
      if (currentUser && found) {
        return next()
      } else {
        return next("/")
      }
    }
  },
  {
    path: "/questions",
    name: "Questions",
    component: () => import("./views/Questions.vue"),
    meta: { layout: 'AppLayoutDefault' },
    beforeEnter: (to, from, next) => {
      const roles = decodeJWT()
      if (!roles.roles) {
        return next("/login")
      }
      const found = roles.roles.some((element) => element == "moderator");
      const currentUser = store.state.auth.status.loggedIn;
      if (currentUser && found) {
        return next()
      } else {
        return next("/")
      }
    }
  },
  { path: "/:pathMatch(.*)*", 
    component: () => import("./views/emptyState.vue"),
    meta: { layout: 'AppLayoutEmpty' } }
];



const router = new createRouter({
  history: createWebHistory(),
  routes,
});



 router.beforeEach(async (to, from, next) => {

  const { cookies } = useCookies();

  const getCookies = cookies.get("access_token")
  const refreshToken = cookies.get("refresh_token")

  // if (getCookies === null && refreshToken === null && to.path != "/login" && to.path != "/forgot-password") {
  //   return next("/login")
  // }

  const roles = decodeJWT()
  const currentDate = moment().unix()

  if (getCookies != null && refreshToken != null && currentDate > roles.exp) {
    await AuthService.refreshToken(refreshToken).then( res => {
      cookies.set("access_token", res.data.access_token)
      console.log(res.data.access_token)
      return next()
    }).catch(err => {
      console.log(err)
      // return next("/login")
    })
  } else {
    return next()
  }
})

export default router;