import AuthService from '../services/auth.service';
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

let user = cookies.get('access_token')
const initialState = user
  ? { status: { loggedIn: true } }
  : { status: { loggedIn: false } };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          if (user.status_code == 200) {
            commit('loginSuccess');
            return Promise.resolve(user);
          } else {
            commit('loginFailure');
            return Promise.reject(user);
          }
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    loginSuccess(state) {
      state.status.loggedIn = true;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
    },
    logout(state) {
      state.status.loggedIn = false;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};