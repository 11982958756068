<template>
  <div class="ma-4">
    <h4 class="mb-4 text-h5 font-weight-bold">Войти в систему</h4>
    <v-form ref="form" @submit.prevent="validate">
      <v-text-field
        v-model="username"
        required
        :rules="rules"
        label="Имя пользователя или email"
        variant="solo"
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="password"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rulesPass.required]"
        :type="show1 ? 'text' : 'password'"
        label="Пароль"
        variant="solo"
        name="input-10-1"
        @click:append="show1 = !show1"
      ></v-text-field>
      <v-alert v-if="message" :class="alert" class="mt-5 mb-5" role="alert">
        {{ message }}
      </v-alert>
      <div class="mb-5 form-group license">
        <v-checkbox
          id="licenses_popup"
          name="licenses_popup"
          value="Y"
          hide-details
          :rules="[(v) => !!v || 'You must agree to continue!']"
          density="compact"
          class="mr-0 mt-0"
          v-model="checked"
        >
          <template v-slot:label>
            <div>
              Я согласен на
              <span class="politicDetail" @click="dialog = true">
                обработку персональных данных
              </span>
            </div>
          </template>
        </v-checkbox>
        <v-dialog v-model="dialog" max-width="800">
          <v-card class="elevation-0 rounded-md v-card--variant-elevated">
            <PoliticsDetails />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="surface-variant"
                text="Закрыть"
                variant="flat"
                @click="dialog = false"
                class="text-none bg-green"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <v-btn
        class="button-height mt-2 v-btn--flat text-white bg-green v-btn--density-default v-btn--size-large v-btn--variant-elevated text-none"
        type="submit"
        block
        :loading="loading"
        >Войти</v-btn
      >
    </v-form>

    <div class="new-account mt-8">
      <router-link :to="{ name: 'ForgotPassword' }" class="back_to">
        Забыли пароль?
      </router-link>
    </div>
  </div>
</template>

<script>
import PoliticsDetails from "./PoliticsDetails.vue";

export default {
  name: "LoginPage",
  components: {
    PoliticsDetails,
  },
  data() {
    return {
      username: "",
      password: "",
      message: "",
      alert: "",
      rules: [
        (value) => {
          if (value) return true;
          return "Ведите имя пользователя или email.";
        },
      ],
      show1: false,
      show2: true,
      dialog: false,
      checked: false,
      rulesPass: {
        required: (value) => {
          if (value) return true;
          return "Обязательное поле.";
        },
        emailMatch: () => `The email and password you entered don't match`,
      },
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    async validate() {
      const { valid } = await this.$refs.form.validate();

      if (valid) this.handleLogin();
    },
    validateEmail() {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (this.username.match(validRegex)) {
        return true;
      } else {
        return false;
      }
    },
    handleLogin() {
      this.loading = true;
      this.message = "";

      const user = {
        password: this.password,
      };

      if (this.validateEmail()) {
        user.email = this.username;
      } else {
        user.username = this.username;
      }

      if (this.username != "" && this.password != "") {
        this.$store
          .dispatch("auth/login", user)
          .then(() => {
            if (this.loggedIn) {
              this.$router.push("/");
            }
          })
          .catch((err) => {
            this.loading = false;
            this.message = "Неверное имя пользователя или пароль";
            if (err.code == "ERR_NETWORK" || err.code == "ERR_BAD_RESPONSE") {
              this.message = "Сервер не доступен";
              return;
            } else {
              this.message = "Неверное имя пользователя или пароль";
            }
          });
      } else {
        this.loading = false;
        this.message = "Заполните обязательные поля";
      }
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/");
    }

    if (this.$route.query.resetpassword == true) {
      this.alert = "bg-green";
      this.message = "Новый пароль успешно сохранен";
    }
  },
};
</script>
