<template>
  <div class="tree-list answer">
    <answer-tree :answer="answerData"></answer-tree>
  </div>
</template>

<script>
import AnswerTree from "./AnswerTree.vue";

export default {
  props: {
    answerData: Object,
  },
  emits: [],
  data() {
    return {};
  },
  components: {
    AnswerTree,
  },
  methods: {},
};
</script>

<style>
.tree-list {
  margin-left: 0px;
}
.tree-item {
  margin-left: 40px;
  border: 1px solid rgb(228 228 228);
  margin: 6px;
  border-radius: 4px;
}
.tree-item .tree-item-top {
  background-color: white;
}
.tree-item-2 {
  margin-left: 40px;
}
.f-50 {
  width: calc(50% - 150px);
  word-break: break-all;
}
</style>
